import React from 'react'
import BlockContent from '@sanity/block-content-to-react'

import { Serializer } from 'src/utils/serializer'
import { Image } from 'src/components/image'

export interface TextImageArrayProps {
  data: {
    color?: string,
    items: Array<{
      image: {
        imageId: string
      }
      text: any[]
    }>
  }
}

export const TextImageArray = ({ data }: TextImageArrayProps) => {
  const {
    color,
    items
  } = data
  return (
    <div className={`bg--${color} color--lite-yellow`}>
      <div className='container--xl p2 outer mxa pt2 pb2 pt4--1000 pb3--1000 al p x'>
        {items.map(item => (
          <div className='row df fw jcb pr mt2 mb2 mt6--800 mb6--800' key={item._key}>
            <div className='col c14 c7--800'>
              <div className='infomercial__text top s60--800'>
                <BlockContent blocks={item.text} serializers={Serializer} />
              </div>
            </div>
            <div className='col c14 c6--800 infomercial__image'>
              <Image className='x' imageId={item.image.imageId}  src={item.image.url} />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default TextImageArray